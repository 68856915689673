/* eslint-disable react/prop-types */
import React from 'react';
import { Link, graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import Seo from '../components/Seo';
import BrandGrid from '../components/BrandGrid/BrandGrid';
import PorfolioCard from '../components/PorfolioCard/PorfolioCard';

export const pageQuery = graphql`
  query PortfolioPageQuery {
    baylor: file(relativePath: { eq: "portfolio/baylor-app.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    amesamoa: file(relativePath: { eq: "portfolio/american-samoa-app.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    valley: file(relativePath: { eq: "portfolio/valley-medical-app.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    umass: file(relativePath: { eq: "portfolio/umasstox-website.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    microgen: file(relativePath: { eq: "portfolio/microgen-website.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    westmed: file(relativePath: { eq: "portfolio/west-med-app.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    sufu: file(relativePath: { eq: "portfolio/sufu-app.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    webfce: file(relativePath: { eq: "portfolio/webfce-website.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    jsp: file(relativePath: { eq: "portfolio/jsp-website.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    advanced: file(relativePath: { eq: "portfolio/advance-app.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    bridge: file(relativePath: { eq: "portfolio/bridge-website.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    madison: file(relativePath: { eq: "portfolio/madison-website.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    cleveland: file(relativePath: { eq: "portfolio/cleveland-website.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    spit: file(relativePath: { eq: "portfolio/giveaspit-website.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    mng: file(relativePath: { eq: "portfolio/mnglabs-website.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    somc: file(relativePath: { eq: "portfolio/somc-website.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

const Porfolio = ({ data }) => {
  return (
    <>
      <Seo
        title="Healthcare Website Client Showcase · Medical Web Experts"
        description="Medical Web Experts offers search engine optimization and internet marketing services to medical practices."
        canonical="/portfolio/"
        schema={`
        {
          "@context": "http://schema.org/",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": "1",
              "item": {
                "@id": "https://www.medicalwebexperts.com",
                "name": "HOME",
                "image": "https://www.medicalwebexperts.com/images/mwe.png"
              }
            },
            {
              "@type": "ListItem",
              "position": "2",
              "item": {
                "@id": "https://www.medicalwebexperts.com/portfolio/",
                "name": "YOUR SUCCESS. OUR TEAM.",
                "image": "https://www.medicalwebexperts.com/images/work/porfolio/umass-porfolio.d4beeb28b553cab8.png"
              }
            }
          ]
        }
        `}
      />
      <BrandGrid colored />
      {/* Pages with Img Main Hero */}
      <div className="d-flex align-items-sm-center full-height-lg ui-layout__main--nmt">
        <Container>
          <Row className="align-items-center justify-content-lg-between">
            <Col lg={{ span: 5, offset: 1 }}>
              <h1 className="mb-5 text-center text-lg-start h1--center-lg">
                Our Portfolio
              </h1>
              <p className="mt-4 mb-5 font-size-lg font-weight-light  text-center text-lg-start">
                Healthcare&apos;s Trusted Provider of Web Development and
                Technology Solutions since 2003
              </p>
              <div className="text-center text-lg-start mb-4">
                <Button as={Link} variant="primary" to="/contact-us">
                  start Now
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* About Content */}
      <Container>
        {/* Our Partners */}
        <Row className="mb-4">
          <Col className="text-center">
            <h2 className="h1 h1--center">Our Work</h2>
          </Col>
        </Row>

        {/* Nav Tabs */}
        <Row className="justify-content-center">
          <Col className="col-md-auto">
            <Tabs
              defaultActiveKey="all"
              id="uncontrolled-tab-example"
              className="justify-content-center mb-6 pb-3 pb-lg-0 unstyled"
            >
              <Tab eventKey="all" title="All">
                {/* Nav Tab Content */}
                <PorfolioCard
                  imgAlt="Custom healthcare mobile app development - Baylor"
                  featuredImage={data.baylor}
                  name="Baylor Scott & White Heart and Vascular Hospital - Dallas & Fort Worth"
                  link="https://apps.apple.com/us/app/baylor-heart-center/id996063617#?platform=iphone"
                  linkLabel="see app"
                  description={
                    <>
                      <p>
                        MWE develops, maintains, and hosts the Baylor Heart
                        Center mobile app suite, which supports iPhone, iPad,
                        and Apple Watch. As a central part of their Digital
                        Front Door strategy, the app includes the following core
                        areas:
                      </p>
                      <ul className="mb-2">
                        <li>
                          <strong>Find a Provider</strong>, which earned the
                          highest honor in the 2020 eHealthcare Leadership
                          Awards for Best Doctor Directory
                        </li>
                        <li>
                          <strong>Learn</strong>, with patient education
                          materials and organization updates
                        </li>
                        <li>
                          <strong>Navigate</strong>, for directions to, from,
                          and within multiple facilities
                        </li>
                        <li>
                          <strong>Meditate</strong>, to help cardiac patients
                          manage their stress. Apple Watch integration allows
                          patients to track a meditation session&apos;s effect
                          on heart rate in real time.
                        </li>
                      </ul>
                      <StaticImage
                        src="../images/site-core/seals/ehla_badge_2020.png"
                        height={60}
                        alt="Winner EHealthcare Leadership Awards 2020"
                      />
                    </>
                  }
                  links={[
                    {
                      to: '/healthcare-app/',
                      label: 'mHealth App Development',
                    },
                    {
                      to: '/health-app-developers/',
                      label: 'Custom Health App Development',
                    },
                  ]}
                />
                <hr className="my-5 my-md-7" />
                <PorfolioCard
                  imgAlt="Custom portal development - American Samoa Department of Health"
                  featuredImage={data.amesamoa}
                  name="American Samoa Department of Health"
                  link="https://americansamoapublichealth.com/"
                  linkLabel="see website"
                  description="The American Samoa Department of Health contracted Medical Web Experts to help facilitate the repatriation of citizens that were stranded in the United States following the closure of borders during the COVID-19 pandemic. In doing so, MWE developed a website designed to provide citizens with a way to apply for repatriation, and a web portal to allow the review and confirmation of applications for repatriation."
                  links={[
                    {
                      to: '/health-app-developers/',
                      label: 'Custom Development',
                    },
                    {
                      to: '/custom-health-patient-portal-development/',
                      label: 'Portal Development',
                    },
                    {
                      to: '/healthcare-website-design/',
                      label: 'Web Design',
                    },
                  ]}
                />
                <hr className="my-5 my-md-7" />
                <PorfolioCard
                  imgAlt="Custom portal development - Valley Medical &amp; Wellness"
                  featuredImage={data.valley}
                  name="Valley Medical &amp; Wellness"
                  link="https://americansamoapublichealth.com/"
                  linkLabel="see website"
                  description={
                    <>
                      <p>
                        Medical Web Experts was brought on board by this
                        multi-location Minnesota-based laboratory to design a
                        web app in response to the surge in COVID-19 testing.
                        Within six weeks, MWE built a mobile-responsive web app
                        with features that allow anyone to schedule a test, make
                        a payment, and view their test results - leading to a
                        highly efficient and effective mass drive-thru testing
                        operation.
                      </p>
                      <p>
                        Built for patients, providers, and Valley Medical staff,
                        the web app includes the following capabilities:
                      </p>
                      <ul className="my-3">
                        <li>
                          Secure and efficient patient intake system and
                          user-friendly registration
                        </li>
                        <li>
                          Pre-appointment patient screening with customizable
                          features
                        </li>
                        <li>
                          Scheduling a test (including ability to cancel or
                          reschedule)
                        </li>
                        <li>
                          Integration with LIS for secure communication of test
                          results
                        </li>
                        <li>
                          Integration with health insurance into bill-pay
                          service, adding real-time eligibility verification
                        </li>
                        <li>
                          Proxy account feature for individuals to book tests on
                          behalf of others
                        </li>
                        <li>
                          Client-facing backend to streamline workflow and
                          communications
                        </li>
                      </ul>
                      <p>
                        In addition to developing the web app, MWE also provides
                        HIPAA-compliant hosting to ensure the data is securely
                        maintained.
                      </p>
                    </>
                  }
                  links={[
                    {
                      to: '/health-app-developers/',
                      label: 'Custom Development',
                    },
                    {
                      to: '/custom-health-patient-portal-development/',
                      label: 'Portal Development',
                    },
                  ]}
                />
                <hr className="my-5 my-md-7" />
                <PorfolioCard
                  imgAlt="Custom healthcare website design - UMASS TOX"
                  featuredImage={data.umass}
                  name="Division of Medical Toxicology at UMass Memorial Medical Center"
                  link="https://umasstox.com/"
                  linkLabel="Visit Site"
                  description={
                    <p>
                      Medical Web Experts worked closely with the UMass Division
                      of Toxicology’s team of physician scientists to plan,
                      write, and develop a{' '}
                      <strong>
                        mobile-responsive website that reflected their culture
                      </strong>
                      , showcased their work, and{' '}
                      <strong>reached a diverse audience:</strong> patients,
                      colleagues, medical trainees, and fund donors. The project
                      included <strong>scripts and animation</strong> for seven
                      educational videos, two{' '}
                      <strong>unique logo designs</strong>, and incorporating{' '}
                      <strong>custom photography</strong>.
                    </p>
                  }
                  links={[
                    {
                      to: '/health-app-developers/',
                      label: 'Custom Development',
                    },
                    {
                      to: '/healthcare-website-design/',
                      label: 'Web Design',
                    },
                  ]}
                />
                <hr className="my-5 my-md-7" />
                <PorfolioCard
                  imgAlt="Custom portal development - MicroGenDX"
                  featuredImage={data.microgen}
                  name="MicroGenDX"
                  link="https://microgendx.com/"
                  linkLabel="Visit Site"
                  description={
                    <>
                      <p>
                        MicroGenDX, a molecular diagnostic laboratory,
                        contracted MWE to build two portals to improve internal
                        and external capabilities and efficiencies. Projects
                        include:
                      </p>
                      <ul className="my-3">
                        <li>
                          Custom integrations into MicroGenDX&apos;s existing
                          systems, including its LIS.
                        </li>
                        <li>
                          A patient portal that delivers COVID-19 test results.
                        </li>
                        <li>
                          A physician portal that allows providers to order lab
                          tests, track shipments, complete and e-sign forms, and
                          view test results. The portal also facilitates
                          collaboration among multiple user roles (including
                          providers, pharmacies, and customer service
                          representatives) by allowing them to communicate with
                          and assign tasks to one another.
                        </li>
                      </ul>
                    </>
                  }
                  links={[
                    {
                      to: '/health-app-developers/',
                      label: 'Custom Development',
                    },
                    {
                      to: '/custom-health-patient-portal-development/',
                      label: 'Portal Development',
                    },
                  ]}
                />
                <hr className="my-5 my-md-7" />
                <PorfolioCard
                  imgAlt="Custom healthcare mobile app development - Westmed"
                  featuredImage={data.westmed}
                  name="Westmed Medical Group"
                  link="https://apps.apple.com/us/app/my-westmed/id1257217365"
                  linkLabel="see app"
                  description={
                    <>
                      <p>
                        Westmed Medical Group’s{' '}
                        <strong>customized, branded mobile app</strong> is built
                        on Universe, Medical Web Experts’ modular mHealth app
                        development platform for large health systems. The app
                        provides Westmed patients with a range of user-friendly
                        digital health services, accessible on the go, such as
                        provider search, location search, urgent care wait
                        times, and news. Additional features such as
                        prescription refill requests, multilingual support,
                        appointment self-scheduling, and online bill pay allow
                        users to gain more control over their healthcare
                        experience. Westmed app also integrates with{' '}
                        <a
                          href="https://www.bridgepatientportal.com/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Bridge Patient Portal.
                        </a>{' '}
                        In 2018 Westmed was awarded with two eHealthcare
                        Leadership Awards for Best Mobile Website and Best
                        Patient Access and Convenience, and again in 2019 for
                        Best Appointment Scheduling and Best Overall Mobile
                        Strategy.
                      </p>
                      <StaticImage
                        src="../images/site-core/seals/ehla_badge_2019.png"
                        height={60}
                        alt="Winner EHealthcare Leadership Awards 2019"
                      />
                    </>
                  }
                  links={[
                    {
                      to: '/mhealth-apps/',
                      label: 'mHealth App Platform',
                    },
                    {
                      to: '/health-app-developers/',
                      label: 'Custom Health App Development',
                    },
                  ]}
                />
                <hr className="my-5 my-md-7" />
                <PorfolioCard
                  imgAlt="Custom healthcare mobile app development - SUFU"
                  featuredImage={data.sufu}
                  name="SUFU"
                  link="https://apps.apple.com/us/app/id1372947470"
                  linkLabel="see app"
                  description="The MyBladder app for the Society of Urodynamics, Female Pelvic Medicine, and Urogenital Reconstruction (SUFU) is available on iOS and Android. Designed to improve the ability of patients to improve adherence and outcomes from overactive bladder treatments, the app allows patients to log daily bladder activity, monitor progress, manage medications, and access resources on how to improve bladder problems."
                  links={[
                    {
                      to: '/health-app-developers/',
                      label: 'Custom Health App Development',
                    },
                  ]}
                />
                <hr className="my-5 my-md-7" />
                <PorfolioCard
                  imgAlt="Healthcare website design - webFCE"
                  featuredImage={data.webfce}
                  name="webFCE"
                  link="https://www.webfce.com/"
                  linkLabel="Visit Site"
                  description="For webFCE, Medical Web Experts developed a
                        mobile-responsive website designed to generate interest
                        in webFCE's web-based software solutions. The
                        website includes a Content Management System,
                        HIPAA-secure forms, access to the client portal, a
                        testimonial carousel and medical content writing for all
                        web pages. MWE has also provided webFCE with digital
                        marketing consultation."
                  links={[
                    {
                      to: '/healthcare-website-design/',
                      label: 'Web Design',
                    },
                  ]}
                />
                <hr className="my-5 my-md-7" />
                <PorfolioCard
                  imgAlt="Healthcare website design &amp; marketing - JSP Home Care Services"
                  featuredImage={data.jsp}
                  name="JSP Home Care Services"
                  link="https://jsphomecare.com/"
                  linkLabel="see website"
                  description="JSP Home Care Services is a licensed home health agency serving New York City. MWE provided the following services for the redesign of the JSP website: a 10-page mobile-responsive website with Content Management System, bilingual content, careers section, custom forms, brand strategy and logo creation. Following the launch of the new website, MWE began a social media marketing campaign designed to generate job applications for open positions at JSP Home Care Services."
                  links={[
                    {
                      to: '/healthcare-website-design/',
                      label: 'Web Design',
                    },
                  ]}
                />
                <hr className="my-5 my-md-7" />
                <PorfolioCard
                  imgAlt="Custom portal development - ASMS"
                  featuredImage={data.advanced}
                  name="Advanced Sleep Medicine Services"
                  link="https://www.sleepdr.com/for-patients/schedule-or-review-an-appointment/"
                  linkLabel="SEE SCHEDULER PAGE"
                  description="MWE, in conjunction with its Bridge Patient Portal product, developed a custom self-scheduling solution for ASMS. It allows patients and caregivers to find next available appointments across multiple locations and/or providers; schedule and manage appointments in real time; receive reminders for appointments and follow-up care via email/SMS; complete registration and intake forms; and schedule appointments on behalf of dependents."
                  links={[
                    {
                      to: '/health-app-developers/',
                      label: 'Custom Development',
                    },
                    {
                      to: '/custom-health-patient-portal-development/',
                      label: 'Portal Development',
                    },
                  ]}
                />
                <hr className="my-5 my-md-7" />
                <PorfolioCard
                  imgAlt="Custom patient portal development - Bridge"
                  featuredImage={data.bridge}
                  name="Bridge Patient Portal"
                  link="https://www.bridgepatientportal.com/"
                  linkLabel="SEE Website"
                  description={
                    <p>
                      Medical Web Experts’ Bridge Patient Portal is a{' '}
                      <strong>
                        fully-customizable medical web portal solution
                      </strong>{' '}
                      that&apos;s unlike any other on the market. Bridge helps
                      healthcare organizations{' '}
                      <strong>
                        engage their patient population, improve profitability
                      </strong>{' '}
                      and <strong>attest for Meaningful Use</strong>. Portal
                      features include online bill pay, appointment requests,
                      and more.
                    </p>
                  }
                  links={[
                    {
                      to: '/healthcare-website-design/',
                      label: 'Web Design',
                    },
                    {
                      to: '/health-app-developers/',
                      label: 'Custom Development',
                    },
                  ]}
                />
                <hr className="my-5 my-md-7" />
                <PorfolioCard
                  imgAlt="Healthcare website design &amp; marketing - Madison Avenue TMS &amp; Psychiatry"
                  featuredImage={data.madison}
                  name="Madison Avenue TMS &amp; Psychiatry"
                  link="https://www.madisonavetms.com/"
                  linkLabel="see website"
                  description={
                    <>
                      <p>
                        At Madison Avenue TMS & Psychiatry, Dr. David Woo
                        admisters Transcranial Magnetic Stimulation, a
                        non-invasive, FDA-approved treatment for patients with
                        depression. MWE developed Madison Avenue’s
                        patient-friendly 10 page website from scratch, including
                        specialized medical content writing, a blog, and
                        WordPress CMS. In addition, MWE created a custom quiz
                        (&apos;Are you a candidate for TMS?&apos;), logo and
                        branding design, custom live-action and 3D video, social
                        media management, paid advertising and search engine
                        optimization.
                      </p>
                      <a
                        href="https://ehealthcareawards.com/2021-winners/best-digital-marketing-campaign/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <StaticImage
                          src="../images/site-core/seals/ehla_badge_2021.png"
                          height={60}
                          alt="Winner EHealthcare Leadership Awards 2021"
                        />
                      </a>
                    </>
                  }
                  links={[
                    {
                      to: '/healthcare-website-design/',
                      label: 'Web Design',
                    },
                  ]}
                />
                <hr className="my-5 my-md-7" />
                <PorfolioCard
                  imgAlt="Custom portal development - Give a Spit"
                  featuredImage={data.spit}
                  name="Give a Spit"
                  description="MWE built a custom portal for Give a Spit, a service that tests for users' risk of breast cancer. Users first complete a preliminary questionnaire to learn their general risk level and whether they're eligible for genetic counseling. After registering, users can access a personalized dashboard, a scheduling system, and messaging platform."
                  links={[
                    {
                      to: '/health-app-developers/',
                      label: 'Custom Development',
                    },
                    {
                      to: '/custom-health-patient-portal-development/',
                      label: 'Portal Development',
                    },
                  ]}
                />
                <hr className="my-5 my-md-7" />
                <PorfolioCard
                  imgAlt="Custom healthcare website design - MNG Labs"
                  featuredImage={data.mng}
                  name="MNG Labs - A LabCorp Company"
                  link="https://mnglabs.com/"
                  linkLabel="Visit Site"
                  description={
                    <p>
                      MWE planned and implemented a 30-page website redesign for
                      MNG Labs - A LabCorp Company. Fully integrated with
                      SEO-optimized content, WordPress CMS, and a blog. This
                      site, for the company specializing in neurogenetic and
                      complex biochemical testing, features a custom built{' '}
                      <a
                        target="_blank"
                        href="https://mnglabs.com/our-tests/test-directory/"
                        rel="noreferrer"
                      >
                        test directory
                      </a>
                      , with the ability to order tests kits, as well as bill
                      pay integration with{' '}
                      <a
                        target="_blank"
                        href="http://authorize.net/"
                        rel="noreferrer"
                      >
                        authorize.net
                      </a>
                      .
                    </p>
                  }
                  links={[
                    {
                      to: '/healthcare-website-design/',
                      label: 'Web Design',
                    },
                  ]}
                />
                <hr className="my-5 my-md-7" />
                <PorfolioCard
                  imgAlt="Custom hospital website design - SOMC"
                  featuredImage={data.somc}
                  name="Southern Ohio Medical Center (SOMC)"
                  link="https://www.somc.org/"
                  linkLabel="Visit Site"
                  description={
                    <p>
                      Medical Web Experts worked with SOMC to design a{' '}
                      <strong>450 page custom medical website</strong> that is
                      easy to navigate, transparent, and provides useful content
                      to site visitors. The website includes a{' '}
                      <strong>mobile version for optimal viewing</strong> across
                      all types of devices and is{' '}
                      <strong>
                        integrated with MWE&apos;s <u>Bridge Patient Portal</u>
                      </strong>
                      , which was tailored to meet the client&apos;s needs.
                    </p>
                  }
                  links={[
                    {
                      to: '/healthcare-website-design/',
                      label: 'Web Design',
                    },
                    {
                      to: '/health-app-developers/',
                      label: 'Custom Development',
                    },
                  ]}
                />
              </Tab>
              <Tab eventKey="mobile-apps" title="Mobile Apps">
                <PorfolioCard
                  imgAlt="Custom healthcare mobile app development - Westmed"
                  featuredImage={data.westmed}
                  name="Westmed Medical Group"
                  link="https://apps.apple.com/us/app/my-westmed/id1257217365"
                  linkLabel="see app"
                  description={
                    <>
                      <p>
                        Westmed Medical Group’s{' '}
                        <strong>customized, branded mobile app</strong> is built
                        on Universe, Medical Web Experts’ modular mHealth app
                        development platform for large health systems. The app
                        provides Westmed patients with a range of user-friendly
                        digital health services, accessible on the go, such as
                        provider search, location search, urgent care wait
                        times, and news. Additional features such as
                        prescription refill requests, multilingual support,
                        appointment self-scheduling, and online bill pay allow
                        users to gain more control over their healthcare
                        experience. Westmed app also integrates with{' '}
                        <a
                          href="https://www.bridgepatientportal.com/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Bridge Patient Portal.
                        </a>{' '}
                        In 2018 Westmed was awarded with two eHealthcare
                        Leadership Awards for Best Mobile Website and Best
                        Patient Access and Convenience, and again in 2019 for
                        Best Appointment Scheduling and Best Overall Mobile
                        Strategy.
                      </p>
                      <StaticImage
                        src="../images/site-core/seals/ehla_badge_2019.png"
                        height={60}
                        alt="Winner EHealthcare Leadership Awards 2019"
                      />
                    </>
                  }
                  links={[
                    {
                      to: '/mhealth-apps/',
                      label: 'mHealth App Platform',
                    },
                    {
                      to: '/health-app-developers/',
                      label: 'Custom Health App Development',
                    },
                  ]}
                />
                <hr className="my-5 my-md-7" />
                <PorfolioCard
                  imgAlt="Custom healthcare mobile app development - SUFU"
                  featuredImage={data.sufu}
                  name="SUFU"
                  link="https://apps.apple.com/us/app/id1372947470"
                  linkLabel="see app"
                  description="The MyBladder app for the Society of Urodynamics, Female Pelvic Medicine, and Urogenital Reconstruction (SUFU) is available on iOS and Android. Designed to improve the ability of patients to improve adherence and outcomes from overactive bladder treatments, the app allows patients to log daily bladder activity, monitor progress, manage medications, and access resources on how to improve bladder problems."
                  links={[
                    {
                      to: '/health-app-developers/',
                      label: 'Custom Health App Development',
                    },
                  ]}
                />
                <hr className="my-5 my-md-7" />
                <PorfolioCard
                  imgAlt=""
                  featuredImage={data.baylor}
                  name="Baylor Scott & White Heart and Vascular Hospital - Dallas & Fort Worth"
                  link="https://apps.apple.com/us/app/baylor-heart-center/id996063617#?platform=iphone"
                  linkLabel="see app"
                  description={
                    <>
                      <p>
                        MWE develops, maintains, and hosts the Baylor Heart
                        Center mobile app suite, which supports iPhone, iPad,
                        and Apple Watch. As a central part of their Digital
                        Front Door strategy, the app includes the following core
                        areas:
                      </p>
                      <ul>
                        <li>
                          <strong>Find a Provider</strong>, which earned the
                          highest honor in the 2020 eHealthcare Leadership
                          Awards for Best Doctor Directory
                        </li>
                        <li>
                          <strong>Learn</strong>, with patient education
                          materials and organization updates
                        </li>
                        <li>
                          <strong>Navigate</strong>, for directions to, from,
                          and within multiple facilities
                        </li>
                        <li>
                          <strong>Meditate</strong>, to help cardiac patients
                          manage their stress. Apple Watch integration allows
                          patients to track a meditation session&apos;s effect
                          on heart rate in real time.
                        </li>
                      </ul>
                      <StaticImage
                        src="../images/site-core/seals/ehla_badge_2021.png"
                        height={60}
                        alt="Winner EHealthcare Leadership Awards 2021"
                      />
                    </>
                  }
                  links={[
                    {
                      to: '/healthcare-app/',
                      label: 'mHealth App Development',
                    },
                    {
                      to: '/health-app-developers/',
                      label: 'Custom Health App Development',
                    },
                  ]}
                />
              </Tab>
              <Tab eventKey="custom-develop" title="Custom Develop">
                <PorfolioCard
                  imgAlt="Custom portal development - Valley Medical &amp; Wellness"
                  featuredImage={data.valley}
                  name="Valley Medical &amp; Wellness"
                  link="https://americansamoapublichealth.com/"
                  linkLabel="see website"
                  description={
                    <>
                      <p>
                        Medical Web Experts was brought on board by this
                        multi-location Minnesota-based laboratory to design a
                        web app in response to the surge in COVID-19 testing.
                        Within six weeks, MWE built a mobile-responsive web app
                        with features that allow anyone to schedule a test, make
                        a payment, and view their test results - leading to a
                        highly efficient and effective mass drive-thru testing
                        operation.
                      </p>
                      <p>
                        Built for patients, providers, and Valley Medical staff,
                        the web app includes the following capabilities:
                      </p>
                      <ul className="my-3">
                        <li>
                          Secure and efficient patient intake system and
                          user-friendly registration
                        </li>
                        <li>
                          Pre-appointment patient screening with customizable
                          features
                        </li>
                        <li>
                          Scheduling a test (including ability to cancel or
                          reschedule)
                        </li>
                        <li>
                          Integration with LIS for secure communication of test
                          results
                        </li>
                        <li>
                          Integration with health insurance into bill-pay
                          service, adding real-time eligibility verification
                        </li>
                        <li>
                          Proxy account feature for individuals to book tests on
                          behalf of others
                        </li>
                        <li>
                          Client-facing backend to streamline workflow and
                          communications
                        </li>
                      </ul>
                      <p>
                        In addition to developing the web app, MWE also provides
                        HIPAA-compliant hosting to ensure the data is securely
                        maintained.
                      </p>
                    </>
                  }
                  links={[
                    {
                      to: '/health-app-developers/',
                      label: 'Custom Development',
                    },
                    {
                      to: '/custom-health-patient-portal-development/',
                      label: 'Portal Development',
                    },
                  ]}
                />
                <hr className="my-5 my-md-7" />
                <PorfolioCard
                  imgAlt="Custom portal development - American Samoa Department of Health"
                  featuredImage={data.amesamoa}
                  name="American Samoa Department of Health"
                  link="https://americansamoapublichealth.com/"
                  linkLabel="see website"
                  description="The American Samoa Department of Health contracted Medical Web Experts to help facilitate the repatriation of citizens that were stranded in the United States following the closure of borders during the COVID-19 pandemic. In doing so, MWE developed a website designed to provide citizens with a way to apply for repatriation, and a web portal to allow the review and confirmation of applications for repatriation."
                  links={[
                    {
                      to: '/health-app-developers/',
                      label: 'Custom Development',
                    },
                    {
                      to: '/custom-health-patient-portal-development/',
                      label: 'Portal Development',
                    },
                    {
                      to: '/healthcare-website-design/',
                      label: 'Web Design',
                    },
                  ]}
                />
                <hr className="my-5 my-md-7" />
                <PorfolioCard
                  imgAlt="Custom portal development - ASMS"
                  featuredImage={data.advanced}
                  name="Advanced Sleep Medicine Services"
                  link="https://www.sleepdr.com/for-patients/schedule-or-review-an-appointment/"
                  linkLabel="SEE SCHEDULER PAGE"
                  description="MWE, in conjunction with its Bridge Patient Portal product, developed a custom self-scheduling solution for ASMS. It allows patients and caregivers to find next available appointments across multiple locations and/or providers; schedule and manage appointments in real time; receive reminders for appointments and follow-up care via email/SMS; complete registration and intake forms; and schedule appointments on behalf of dependents."
                  links={[
                    {
                      to: '/health-app-developers/',
                      label: 'Custom Development',
                    },
                    {
                      to: '/custom-health-patient-portal-development/',
                      label: 'Portal Development',
                    },
                  ]}
                />
                <hr className="my-5 my-md-7" />
                <PorfolioCard
                  imgAlt="Custom portal development - Give a Spit"
                  featuredImage={data.spit}
                  name="Give a Spit"
                  description="MWE built a custom portal for Give a Spit, a service that tests for users' risk of breast cancer. Users first complete a preliminary questionnaire to learn their general risk level and whether they're eligible for genetic counseling. After registering, users can access a personalized dashboard, a scheduling system, and messaging platform."
                  links={[
                    {
                      to: '/health-app-developers/',
                      label: 'Custom Development',
                    },
                    {
                      to: '/custom-health-patient-portal-development/',
                      label: 'Portal Development',
                    },
                  ]}
                />
                <hr className="my-5 my-md-7" />
                <PorfolioCard
                  imgAlt="Custom healthcare website design - UMASS TOX"
                  featuredImage={data.umass}
                  name="Division of Medical Toxicology at UMass Memorial Medical Center"
                  link="https://umasstox.com/"
                  linkLabel="Visit Site"
                  description={
                    <p>
                      Medical Web Experts worked closely with the UMass Division
                      of Toxicology’s team of physician scientists to plan,
                      write, and develop a{' '}
                      <strong>
                        mobile-responsive website that reflected their culture
                      </strong>
                      , showcased their work, and{' '}
                      <strong>reached a diverse audience:</strong> patients,
                      colleagues, medical trainees, and fund donors. The project
                      included <strong>scripts and animation</strong> for seven
                      educational videos, two{' '}
                      <strong>unique logo designs</strong>, and incorporating{' '}
                      <strong>custom photography</strong>.
                    </p>
                  }
                  links={[
                    {
                      to: '/health-app-developers/',
                      label: 'Custom Development',
                    },
                    {
                      to: '/healthcare-website-design/',
                      label: 'Web Design',
                    },
                  ]}
                />
                <hr className="my-5 my-md-7" />
                <PorfolioCard
                  imgAlt="Custom portal development - MicroGenDX"
                  featuredImage={data.microgen}
                  name="MicroGenDX"
                  link="https://microgendx.com/"
                  linkLabel="Visit Site"
                  description={
                    <>
                      <p>
                        MicroGenDX, a molecular diagnostic laboratory,
                        contracted MWE to build two portals to improve internal
                        and external capabilities and efficiencies. Projects
                        include:
                      </p>
                      <ul className="my-3">
                        <li>
                          Custom integrations into MicroGenDX&apos;s existing
                          systems, including its LIS.
                        </li>
                        <li>
                          A patient portal that delivers COVID-19 test results.
                        </li>
                        <li>
                          A physician portal that allows providers to order lab
                          tests, track shipments, complete and e-sign forms, and
                          view test results. The portal also facilitates
                          collaboration among multiple user roles (including
                          providers, pharmacies, and customer service
                          representatives) by allowing them to communicate with
                          and assign tasks to one another.
                        </li>
                      </ul>
                    </>
                  }
                  links={[
                    {
                      to: '/health-app-developers/',
                      label: 'Custom Development',
                    },
                    {
                      to: '/custom-health-patient-portal-development/',
                      label: 'Portal Development',
                    },
                  ]}
                />
                <hr className="my-5 my-md-7" />
                <PorfolioCard
                  imgAlt="Custom hospital website design - SOMC"
                  featuredImage={data.somc}
                  name="Southern Ohio Medical Center (SOMC)"
                  link="https://www.somc.org/"
                  linkLabel="Visit Site"
                  description={
                    <p>
                      Medical Web Experts worked with SOMC to design a{' '}
                      <strong>450 page custom medical website</strong> that is
                      easy to navigate, transparent, and provides useful content
                      to site visitors. The website includes a{' '}
                      <strong>mobile version for optimal viewing</strong> across
                      all types of devices and is{' '}
                      <strong>
                        integrated with MWE&apos;s <u>Bridge Patient Portal</u>
                      </strong>
                      , which was tailored to meet the client&apos;s needs.
                    </p>
                  }
                  links={[
                    {
                      to: '/healthcare-website-design/',
                      label: 'Web Design',
                    },
                    {
                      to: '/health-app-developers/',
                      label: 'Custom Development',
                    },
                  ]}
                />
                <hr className="my-5 my-md-7" />
                <PorfolioCard
                  imgAlt="Custom patient portal development - Bridge"
                  featuredImage={data.bridge}
                  name="Bridge Patient Portal"
                  link="https://www.bridgepatientportal.com/"
                  linkLabel="SEE Website"
                  description={
                    <p>
                      Medical Web Experts’ Bridge Patient Portal is a{' '}
                      <strong>
                        fully-customizable medical web portal solution
                      </strong>{' '}
                      that&apos;s unlike any other on the market. Bridge helps
                      healthcare organizations{' '}
                      <strong>
                        engage their patient population, improve profitability
                      </strong>{' '}
                      and <strong>attest for Meaningful Use</strong>. Portal
                      features include online bill pay, appointment requests,
                      and more.
                    </p>
                  }
                  links={[
                    {
                      to: '/healthcare-website-design/',
                      label: 'Web Design',
                    },
                    {
                      to: '/health-app-developers/',
                      label: 'Custom Development',
                    },
                  ]}
                />
              </Tab>
              <Tab eventKey="web-design" title="Web Design">
                <PorfolioCard
                  imgAlt="Custom healthcare website design - MNG Labs"
                  featuredImage={data.mng}
                  name="MNG Labs - A LabCorp Company"
                  link="https://mnglabs.com/"
                  linkLabel="Visit Site"
                  description={
                    <p>
                      MWE planned and implemented a 30-page website redesign for
                      MNG Labs - A LabCorp Company. Fully integrated with
                      SEO-optimized content, WordPress CMS, and a blog. This
                      site, for the company specializing in neurogenetic and
                      complex biochemical testing, features a custom built{' '}
                      <a
                        target="_blank"
                        href="https://mnglabs.com/our-tests/test-directory/"
                        rel="noreferrer"
                      >
                        test directory
                      </a>
                      , with the ability to order tests kits, as well as bill
                      pay integration with{' '}
                      <a
                        target="_blank"
                        href="http://authorize.net/"
                        rel="noreferrer"
                      >
                        authorize.net
                      </a>
                      .
                    </p>
                  }
                  links={[
                    {
                      to: '/healthcare-website-design/',
                      label: 'Web Design',
                    },
                  ]}
                />
                <hr className="my-5 my-md-7" />
                <PorfolioCard
                  imgAlt="Healthcare website design &amp; marketing - Madison Avenue TMS &amp; Psychiatry"
                  featuredImage={data.madison}
                  name="Madison Avenue TMS &amp; Psychiatry"
                  link="https://www.madisonavetms.com/"
                  linkLabel="see website"
                  description={
                    <>
                      <p>
                        At Madison Avenue TMS & Psychiatry, Dr. David Woo
                        admisters Transcranial Magnetic Stimulation, a
                        non-invasive, FDA-approved treatment for patients with
                        depression. MWE developed Madison Avenue’s
                        patient-friendly 10 page website from scratch, including
                        specialized medical content writing, a blog, and
                        WordPress CMS. In addition, MWE created a custom quiz
                        (&quote;Are you a candidate for TMS?&quote;), logo and
                        branding design, custom live-action and 3D video, social
                        media management, paid advertising and search engine
                        optimization.
                      </p>
                      <StaticImage
                        src="../images/site-core/seals/ehla_badge_2021.png"
                        height={60}
                        alt="Winner EHealthcare Leadership Awards 2021"
                      />
                    </>
                  }
                  links={[
                    {
                      to: '/healthcare-website-design/',
                      label: 'Web Design',
                    },
                  ]}
                />
                <hr className="my-5 my-md-7" />
                <PorfolioCard
                  imgAlt="Custom healthcare website design - UMASS TOX"
                  featuredImage={data.umass}
                  name="Division of Medical Toxicology at UMass Memorial Medical Center"
                  link="https://umasstox.com/"
                  linkLabel="Visit Site"
                  description={
                    <p>
                      Medical Web Experts worked closely with the UMass Division
                      of Toxicology’s team of physician scientists to plan,
                      write, and develop a{' '}
                      <strong>
                        mobile-responsive website that reflected their culture
                      </strong>
                      , showcased their work, and{' '}
                      <strong>reached a diverse audience:</strong> patients,
                      colleagues, medical trainees, and fund donors. The project
                      included <strong>scripts and animation</strong> for seven
                      educational videos, two{' '}
                      <strong>unique logo designs</strong>, and incorporating{' '}
                      <strong>custom photography</strong>.
                    </p>
                  }
                  links={[
                    {
                      to: '/health-app-developers/',
                      label: 'Custom Development',
                    },
                    {
                      to: '/healthcare-website-design/',
                      label: 'Web Design',
                    },
                  ]}
                />
                <hr className="my-5 my-md-7" />
                <PorfolioCard
                  imgAlt="Custom hospital website design - SOMC"
                  featuredImage={data.somc}
                  name="Southern Ohio Medical Center (SOMC)"
                  link="https://www.somc.org/"
                  linkLabel="Visit Site"
                  description={
                    <p>
                      Medical Web Experts worked with SOMC to design a{' '}
                      <strong>450 page custom medical website</strong> that is
                      easy to navigate, transparent, and provides useful content
                      to site visitors. The website includes a{' '}
                      <strong>mobile version for optimal viewing</strong> across
                      all types of devices and is{' '}
                      <strong>
                        integrated with MWE&apos;s <u>Bridge Patient Portal</u>
                      </strong>
                      , which was tailored to meet the client&apos;s needs.
                    </p>
                  }
                  links={[
                    {
                      to: '/healthcare-website-design/',
                      label: 'Web Design',
                    },
                    {
                      to: '/health-app-developers/',
                      label: 'Custom Development',
                    },
                  ]}
                />
                <hr className="my-5 my-md-7" />
                <PorfolioCard
                  imgAlt="Custom portal development - American Samoa Department of Health"
                  featuredImage={data.amesamoa}
                  name="American Samoa Department of Health"
                  link="https://americansamoapublichealth.com/"
                  linkLabel="see website"
                  description="The American Samoa Department of Health contracted Medical Web Experts to help facilitate the repatriation of citizens that were stranded in the United States following the closure of borders during the COVID-19 pandemic. In doing so, MWE developed a website designed to provide citizens with a way to apply for repatriation, and a web portal to allow the review and confirmation of applications for repatriation."
                  links={[
                    {
                      to: '/health-app-developers/',
                      label: 'Custom Development',
                    },
                    {
                      to: '/custom-health-patient-portal-development/',
                      label: 'Portal Development',
                    },
                    {
                      to: '/healthcare-website-design/',
                      label: 'Web Design',
                    },
                  ]}
                />
                <hr className="my-5 my-md-7" />
                <PorfolioCard
                  imgAlt="Healthcare website design - webFCE"
                  featuredImage={data.webfce}
                  name="webFCE"
                  link="https://www.webfce.com/"
                  linkLabel="Visit Site"
                  description="For webFCE, Medical Web Experts developed a
                        mobile-responsive website designed to generate interest
                        in webFCE's web-based software solutions. The
                        website includes a Content Management System,
                        HIPAA-secure forms, access to the client portal, a
                        testimonial carousel and medical content writing for all
                        web pages. MWE has also provided webFCE with digital
                        marketing consultation."
                  links={[
                    {
                      to: '/healthcare-website-design/',
                      label: 'Web Design',
                    },
                  ]}
                />
                <hr className="my-5 my-md-7" />
                <PorfolioCard
                  imgAlt="Healthcare website design &amp; marketing - JSP Home Care Services"
                  featuredImage={data.jsp}
                  name="JSP Home Care Services"
                  link="https://jsphomecare.com/"
                  linkLabel="see website"
                  description="JSP Home Care Services is a licensed home health agency serving New York City. MWE provided the following services for the redesign of the JSP website: a 10-page mobile-responsive website with Content Management System, bilingual content, careers section, custom forms, brand strategy and logo creation. Following the launch of the new website, MWE began a social media marketing campaign designed to generate job applications for open positions at JSP Home Care Services."
                  links={[
                    {
                      to: '/healthcare-website-design/',
                      label: 'Web Design',
                    },
                  ]}
                />
                <hr className="my-5 my-md-7" />
                <PorfolioCard
                  imgAlt="Custom patient portal development - Bridge"
                  featuredImage={data.bridge}
                  name="Bridge Patient Portal"
                  link="https://www.bridgepatientportal.com/"
                  linkLabel="SEE Website"
                  description={
                    <p>
                      Medical Web Experts’ Bridge Patient Portal is a{' '}
                      <strong>
                        fully-customizable medical web portal solution
                      </strong>{' '}
                      that&apos;s unlike any other on the market. Bridge helps
                      healthcare organizations{' '}
                      <strong>
                        engage their patient population, improve profitability
                      </strong>{' '}
                      and <strong>attest for Meaningful Use</strong>. Portal
                      features include online bill pay, appointment requests,
                      and more.
                    </p>
                  }
                  links={[
                    {
                      to: '/healthcare-website-design/',
                      label: 'Web Design',
                    },
                    {
                      to: '/health-app-developers/',
                      label: 'Custom Development',
                    },
                  ]}
                />
              </Tab>
              <Tab eventKey="case-studies" title="Case Studies">
                <Row className="justify-content-center">
                  <Col lg={8}>
                    <h3 className="ui-porfolio-work--title mb-4">
                      Custom Telehealth Platform for a Nationwide Provider
                    </h3>
                    <p>
                      Medical Web Experts built a custom, scalable telehealth
                      platform to help a nationwide telehealth provider handle
                      the dramatic rise in demand for telehealth services. The
                      new cloud-based platform processes 20,000 requests per
                      minute and delivers 95% of test results within 24 hours,
                      as well as offers a modern suite of portal features to
                      patients.
                    </p>
                    <a
                      href="/pdf/MWE_TelehealthProviderCaseStudy.pdf"
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-link mb-3 ui-porfolio-work--btn"
                    >
                      See Case Study (PDF)
                    </a>
                    <ul className="ui-porfolio-work--category m-0 p-0 d-flex pt-4 unstyled">
                      <li>
                        <StaticImage
                          src="../images/site-core/icons/category-icon.svg"
                          className="me-2"
                          alt="Category link"
                        />
                      </li>
                      <li>
                        <Link
                          to="/health-app-developers/"
                          className="ui-porfolio-work--category-link font-size-xs text-muted text-decoration-none d-block me-2"
                        >
                          Custom Development
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/custom-health-patient-portal-development/"
                          className="ui-porfolio-work--category-link font-size-xs text-muted text-decoration-none d-block me-2"
                        >
                          Portal Development
                        </Link>
                      </li>
                    </ul>
                    <hr className="my-5 my-md-7" />
                  </Col>
                </Row>

                <Row className="justify-content-center">
                  <Col lg={8}>
                    <h3 className="ui-porfolio-work--title mb-4">
                      Web Design, Content Creation, and Video for the Division
                      of Medical Toxicology at UMass Memorial Medical Center
                    </h3>
                    <p>
                      As trailblazing researchers and physicians, the Division
                      of Medical Toxicology team at UMass Memorial Medical
                      Center lacked a platform for showcasing its work and
                      reaching its broad audience of colleagues, medical
                      trainees, and patients and families. Medical Web Experts
                      took on the challenge, proposing a custom website design,
                      development, and content project that would reflect the
                      personality of the Division and meet its objectives.
                    </p>
                    <a
                      href="https://www.medicalwebexperts.com/pdf/design-case-study-umass-tox.pdf"
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-link mb-3 ui-porfolio-work--btn"
                    >
                      See Case Study (PDF)
                    </a>
                    <ul className="ui-porfolio-work--category m-0 p-0 d-flex pt-4 unstyled">
                      <li>
                        <StaticImage
                          src="../images/site-core/icons/category-icon.svg"
                          className="me-2"
                          alt="Category link"
                        />
                      </li>
                      <li>
                        <Link
                          to="/healthcare-website-design/"
                          className="ui-porfolio-work--category-link font-size-xs text-muted text-decoration-none d-block me-2"
                        >
                          Web Design Services
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/healthcare-marketing/"
                          className="ui-porfolio-work--category-link font-size-xs text-muted text-decoration-none d-block me-2"
                        >
                          Marketing Services
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/medical-content-writing/"
                          className="ui-porfolio-work--category-link font-size-xs text-muted text-decoration-none d-block me-2"
                        >
                          Content Writing Services
                        </Link>
                      </li>
                    </ul>
                    <hr className="my-5 my-md-7" />
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col lg={8}>
                    <h3 className="ui-porfolio-work--title mb-4">
                      Website Design & Marketing for a Specialty Pharmacy
                    </h3>
                    <p>
                      Medical Web Experts built a mobile- and tablet-friendly
                      site for this specialty pharmacy, including professional
                      photos and a custom video shot on-site. A digital
                      marketing campaign launched shortly after the site went
                      live, and resulted in a{' '}
                      <strong>
                        five-fold increase of new visitors to the site
                      </strong>{' '}
                      as well as{' '}
                      <strong>a 2000% increase in conversions</strong>.
                    </p>
                    <a
                      href="/pdf/Pharmacy-CaseStudy.pdf"
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-link mb-3 ui-porfolio-work--btn"
                    >
                      See Case Study (PDF)
                    </a>
                    <ul className="ui-porfolio-work--category m-0 p-0 d-flex pt-4 unstyled">
                      <li>
                        <StaticImage
                          src="../images/site-core/icons/category-icon.svg"
                          className="me-2"
                          alt="Category link"
                        />
                      </li>
                      <li>
                        <Link
                          to="/healthcare-website-design/"
                          className="ui-porfolio-work--category-link font-size-xs text-muted text-decoration-none d-block me-2"
                        >
                          Web Design Services
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/healthcare-marketing/"
                          className="ui-porfolio-work--category-link font-size-xs text-muted text-decoration-none d-block me-2"
                        >
                          Marketing Services
                        </Link>
                      </li>
                    </ul>
                    <hr className="my-5 my-md-7" />
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col lg={8}>
                    <h3 className="ui-porfolio-work--title mb-4">
                      Content Writing for MedStar Washington Hospital Center
                    </h3>
                    <p>
                      To enhance its web presence, MedStar Washington Hospital
                      Center engaged Medical Web Experts as a content creation
                      partner. Working with the hospital’s marketing team and
                      healthcare providers, Medical Web Experts has created over
                      100 web pages and blog posts, including patient education
                      materials, landing pages, physician bios, and patient
                      stories across more than 20 medical specialties.
                    </p>
                    <a
                      href="/pdf/CaseStudyMedStar.pdf"
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-link mb-3 ui-porfolio-work--btn"
                    >
                      See Case Study (PDF)
                    </a>
                    <ul className="ui-porfolio-work--category m-0 p-0 d-flex pt-4 unstyled">
                      <li>
                        <StaticImage
                          src="../images/site-core/icons/category-icon.svg"
                          className="me-2"
                          alt="Category link"
                        />
                      </li>
                      <li>
                        <Link
                          to="/medical-content-writing/"
                          className="ui-porfolio-work--category-link font-size-xs text-muted text-decoration-none d-block me-2"
                        >
                          Content Writing Services
                        </Link>
                      </li>
                    </ul>
                    <hr className="my-5 my-md-7" />
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col lg={8}>
                    <h3 className="ui-porfolio-work--title mb-4">
                      Web Development, Marketing, & Consulting for a Leading
                      Fertility Center
                    </h3>
                    <p>
                      Medical Web Experts developed a multi-pronged strategy to
                      help a world renowned fertility treatment and research
                      center to help it better reach its patients. Becoming the
                      client&apos;s full-service web development, marketing, and
                      sales consulting partner, we achieved a 68% increase in
                      organic site traffic on mobile devices and a four-fold
                      increase in the patient appointment rate.
                    </p>
                    <a
                      href="/pdf/fertility-center-case-study.pdf"
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-link mb-3 ui-porfolio-work--btn"
                    >
                      See Case Study (PDF)
                    </a>
                    <ul className="ui-porfolio-work--category m-0 p-0 d-flex pt-4 unstyled">
                      <li>
                        <StaticImage
                          src="../images/site-core/icons/category-icon.svg"
                          className="me-2"
                          alt="Category link"
                        />
                      </li>
                      <li>
                        <Link
                          to="/healthcare-website-design/"
                          className="ui-porfolio-work--category-link font-size-xs text-muted text-decoration-none d-block me-2"
                        >
                          Web Design Services
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/healthcare-marketing/"
                          className="ui-porfolio-work--category-link font-size-xs text-muted text-decoration-none d-block me-2"
                        >
                          Marketing Services
                        </Link>
                      </li>
                    </ul>
                    <hr className="my-5 my-md-7" />
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col lg={8}>
                    <h3 className="ui-porfolio-work--title mb-4">
                      Multi-channel Digital Marketing for a Psychiatry Practice
                    </h3>
                    <p>
                      Medical Web Experts launched a marketing campaign for a
                      New York City-based psychiatry practice that specializes
                      in Transcranial Magnetic Stimulation (TMS). TMS is an
                      FDA-approved treatment for depression that’s covered by
                      insurance. The campaign has been extremely successful,
                      having generated an estimated return on investment (“ROI”)
                      of 276% from 2019-2020.
                    </p>
                    <a
                      href="/pdf/Multi-channelDigitalMarketingCampaign.pdf"
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-link mb-3 ui-porfolio-work--btn"
                    >
                      See Case Study (PDF)
                    </a>
                    <ul className="ui-porfolio-work--category m-0 p-0 d-flex pt-4 unstyled">
                      <li>
                        <StaticImage
                          src="../images/site-core/icons/category-icon.svg"
                          className="me-2"
                          alt="Category link"
                        />
                      </li>
                      <li>
                        <Link
                          to="/healthcare-website-design/"
                          className="ui-porfolio-work--category-link font-size-xs text-muted text-decoration-none d-block me-2"
                        >
                          Web Design Services
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/healthcare-marketing/"
                          className="ui-porfolio-work--category-link font-size-xs text-muted text-decoration-none d-block me-2"
                        >
                          Marketing Services
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/medical-content-writing/"
                          className="ui-porfolio-work--category-link font-size-xs text-muted text-decoration-none d-block me-2"
                        >
                          Content Writing Services
                        </Link>
                      </li>
                    </ul>
                    <hr className="my-5 my-md-7" />
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col lg={8}>
                    <h3 className="ui-porfolio-work--title mb-4">
                      Custom Lab Portal Development for Mass COVID-19 Testing
                    </h3>
                    <p>
                      Medical Web Experts built a mobile-responsive web app in
                      six weeks to support the mass increase in COVID-19 testing
                      for a Minnesota-based lab with multiple locations. The lab
                      was able to quadruple its testing capacity, performing
                      ~500 drive through tests per day and delivering 95% of
                      test results to patients within 24 hours.
                    </p>
                    <a
                      href="/pdf/CustomLabPortalDevelopment.pdf"
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-link mb-3 ui-porfolio-work--btn"
                    >
                      See Case Study (PDF)
                    </a>
                    <ul className="ui-porfolio-work--category m-0 p-0 d-flex pt-4 unstyled">
                      <li>
                        <StaticImage
                          src="../images/site-core/icons/category-icon.svg"
                          className="me-2"
                          alt="Category link"
                        />
                      </li>
                      <li>
                        <Link
                          to="/custom-lab-result-portal-development/"
                          className="ui-porfolio-work--category-link font-size-xs text-muted text-decoration-none d-block me-2"
                        >
                          Lab Portal APP Development Services
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/custom-lab-result-portal-development/"
                          className="ui-porfolio-work--category-link font-size-xs text-muted text-decoration-none d-block me-2"
                        >
                          Lab Website Development Services
                        </Link>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Porfolio;
